import * as React from 'react';
import { graphql } from 'gatsby';
import { Layout, Testimonials } from '../../components';
import styles from './styles/index.module.scss';

export interface GatsbyLocation extends Location {
  state: { [key: string]: string };
}

export interface PageProps {
  location: GatsbyLocation;
  data: any;
  pageContext: any;
}

const IndexPage: React.FunctionComponent<PageProps> = (props) => {
  const { locale } = props.pageContext;
  const { frontmatter, html } = props.data.markdownRemark;

  return (
    <Layout locale={locale} location={props.location}>
      <main>
        <section className={[styles.article].join(' ')}>
          <div
            className={[styles.articleImage].join(' ')}
            style={{
              backgroundImage: ' url(' + frontmatter.image + ')',
            }}
          ></div>
          <article className={[styles.articleContent].join(' ')}>
            <header className={[styles.articleContentHeader].join(' ')}>
              {frontmatter.title}
            </header>
            <section
              className={[styles.articleContentText].join(' ')}
              dangerouslySetInnerHTML={{ __html: html }}
            ></section>
          </article>
        </section>
        <Testimonials testimonials={frontmatter.testimonials}/>

      </main>
    </Layout>
  );
};

export const query = graphql`
  query($filepath: String!) {
    markdownRemark(fileAbsolutePath: { eq: $filepath }) {
      html
      frontmatter {
        nav_menu
        image
        title
        short_description
        path
        type
        testimonials{
          author
          quote
        }
      }
    }
  }
`;

export default IndexPage;
